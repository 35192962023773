import React, { useContext } from "react";
import "./style.scss";
import AppContext from "../../context";

const Entert = () => {
  const { entertList } = useContext(AppContext);

  return (
    <div className="entert" id="entert">
      <div className="content-entert">
        <h1>Развлечения</h1>
        <div className="cards-entert">
          {entertList.map((entert) => (
            <div key={entert.name} className="card-entert">
              {entert.prompt && (
                <span className="material-symbols-outlined">error</span>
              )}
              <span className="tooltip">{entert.prompt}</span>
              <img
                className="ellipse-entert"
                src="/img/entert/ellipse-entert.png"
                alt="ellipse"
              />
              <img
                className="item-entert"
                src={`${process.env.REACT_APP_BACKEND_URL}${entert.image}`}
                alt="ship"
              />
              <span className="price-entert">
                {entert.price} руб./{entert.duration} минут
              </span>
              <h2>{entert.name}</h2>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Entert;
