import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import About from "./pages/About/About";
import Entert from "./pages/Entertainment/Entert";
import Gallery from "./pages/Gallery/Gallery";
import Home from "./pages/Home/Home";
import House from "./pages/Houses/House";
import Order from "./pages/Order/Order";
import Rest from "./pages/Rest/Rest";
import Contacts from "./pages/Contacts/Contacts";
import { useScroll } from "framer-motion";
import axios from "axios";
import React, { useEffect, useState } from "react";
import AppContext from "./context";
import Menu from "./pages/Menu/Menu";

function App() {
  useEffect(() => {
    async function fetchData() {
      const settings = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/settings/`
      );
      const houses = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/house/`
      );
      const enterts = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/entertainments/`
      );
      const services = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/services/`
      );
      const gallery = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/gallery/`
      );
      const menu = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/restaurant/menu/`
      );
      const restPhoto = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/restaurant/images/`
      );

      setHouseList(houses.data);
      setEntertList(enterts.data);
      setServiceList(services.data);
      setGalleryList(gallery.data);
      setSettingsList(settings.data);
      setMenuList(menu.data);
      setRestPhotoList(restPhoto.data);
    }
    fetchData();
  }, []);
  const [entertList, setEntertList] = useState([]);
  const [houseList, setHouseList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [galleryList, setGalleryList] = useState([]);
  const [settingsList, setSettingsList] = useState([]);
  const [restPhotoList, setRestPhotoList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const { scrollY } = useScroll();
  const offsetY = [0, 300];

  return (
    <div className="App">
      <AppContext.Provider
        value={{
          houseList,
          entertList,
          serviceList,
          galleryList,
          settingsList,
          menuList,
          restPhotoList,
        }}
      >
        <Router>
          <Routes>
            <Route path="/menu" element={<Menu />} />
            <Route
              path="/"
              element={
                <>
                  <Navbar offsetY={offsetY} scrollY={scrollY} />
                  <Home />
                  <About />
                  <House />
                  <Rest />
                  <Entert />
                  {/* <Order /> */}
                  <Gallery />
                  <Contacts />
                </>
              }
            ></Route>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
      </AppContext.Provider>
    </div>
  );
}
export default App;
