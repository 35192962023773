import React, { useContext, useState } from 'react';
import './style.scss';
import { Link, animateScroll as scroll } from "react-scroll";
import { motion, useTransform } from 'framer-motion';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import AppContext from '../../context';


const Navbar = ({offsetY, scrollY}) => {

    const heightSizes = [112, 70];
    const imgSizes = [72, 50];
    const btnSizes = [112, 70];
    const [nav, setNav] = useState(false);
    const { settingsList } = useContext(AppContext);


    const height = useTransform(scrollY, offsetY, heightSizes);
    const imgWidth = useTransform(scrollY, offsetY, imgSizes);
    const btnHeight = useTransform(scrollY, offsetY, btnSizes);




    const openMenu = () => {
        if (window.screen.availWidth <= 480) {
            setNav((prev) => !prev);
            if (!nav)
                document.querySelector('body').classList.add('active');
            else 
                document.querySelector('body').classList.remove('active');
        }
        
    }


    return (
        <div className="nav">
            <motion.div className="header-nav"
            style={{
                height: height
            }}  >
                <div className='mobile-btn' onClick={openMenu}>
                    {
                        nav ? <AiOutlineClose size={35} color='#ffffff' /> : <AiOutlineMenu size={35} color='#ffffff' />
                    }
                </div>
                <nav className={nav ? 'navbar-burger-menu navbar-burger-menu active' : 'navbar-burger-menu'} onClick={openMenu} >
                <div className="navbar-menu-content" onClick={e => e.stopPropagation()}>

                    <ul className='header-nav-links' >
                    <div className="logo">
                <Link
                    activeClass="active"
                    to='home'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={700} 
                    onClick={openMenu}>
                    <motion.img src="/img/logo1.png" style={{width: imgWidth}} alt="" />
                </Link>
                </div>
                    <li className='header-nav-link'>
                        <Link
                            activeClass="active"
                            to='about'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            onClick={openMenu}>
                                О нас
                        </Link></li>
                        <li className='header-nav-link'>
                            <Link
                            activeClass="active"
                            to='house'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            onClick={openMenu}>
                                Домики
                        </Link></li>
                        <li className='header-nav-link'>
                            <Link
                                activeClass="active"
                                to='rest'
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={700}
                                onClick={openMenu}>
                                    Ресторан
                            </Link></li>
                        <li className='header-nav-link'>
                            <Link
                                activeClass="active"
                                to='entert'
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={700}
                                onClick={openMenu}>
                                    Досуг
                            </Link></li>
                        <li className='header-nav-link'>
                        <Link
                            activeClass="active"
                            to='gallery'
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={700}
                            onClick={openMenu}>
                                Галерея
                        </Link></li>
                        <li className='header-nav-link'>
                            <Link
                                activeClass="active"
                                to='road'
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={700}
                                onClick={openMenu}>
                                    Контакты
                            </Link></li>
                    </ul>
                </div>
                </nav>
                <Link
                    to='road'
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={700}
                    className='reserv'>
                    <motion.button className='btn-nav-order' 
                style={{
                    height: btnHeight
                }}>Забронировать</motion.button></Link>
            </motion.div>
            <div className='right-column'>
                <div className="icons">
                    <a href={settingsList.insta_link} className='inst-icon'><img src="/img/inst.png"  width={36} height={36} alt="inst" /></a>
                    <a href={settingsList.vk_link} target="_blank" rel="noopener noreferrer" className='vk-icon'><img src="/img/vk.png"  width={36} height={36} alt="vk" /></a>
                </div>
                <p>Республика Башкортостан, Мелеузовский район Нугушское водохранилище</p>
            </div>
        </div>
    );
};

export default Navbar;