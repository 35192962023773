import React, { useContext, useState } from "react";
import AppContext from "../context";
import { Swiper, SwiperSlide } from "swiper/react";
import "./Modal/style.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import Modal from "./Modal/Modal";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const CardsHouse = () => {
  const { houseList } = useContext(AppContext);
  const [modalHouse, setModalHouse] = useState(false);
  const [housePhotoList, setHousePhotoList] = useState([]);
  const extensions = ["png", "jpg", "jpeg", "tif"];

  const openModalHouse = (target) => {
    setModalHouse(true);
    document.querySelector("body").classList.add("active");
    const id = target.getAttribute("data-id");
    houseList.forEach((d) => {
      if (d.id == +id) {
        setHousePhotoList(d.images);
      }
    });
  };
  const [value, setValue] = useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className="card-houses-content">
      {houseList.map((house) => (
        <div key={house.name} className="content-house-desktop">
          <div className="content-main">
            <div className="content-photo-main">
              <img
                src={`${process.env.REACT_APP_BACKEND_URL}${house.main_image}`}
                alt="house1"
              />
            </div>
            <div className="desc-house">
              <div className="title-house">
                <h3>{house.name}</h3>
              </div>
              <div className="items-house">
                {house.services.map((service) => (
                  <div key={service.name} className="item-house">
                    <h4>{service.name}</h4>
                  </div>
                ))}
              </div>
              <>
                <div className="price-menu">
                  <h2>Прайс</h2>
                  <FormControl sx={{ m: 1, minWidth: 180 }} size="small">
                    <InputLabel id="demo-select-small-label" sx={{pointerEvents: 'none'}}>
                      Выберите сезон
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={value}
                      label="Выберите сезон"
                      onChange={handleChange}
                    >
                      {house.prices.map((item) => (
                        <MenuItem value={item.type}>{item.type}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {house.prices.map(
                  (item) =>
                    value === item.type && (
                      <div className="price-house">
                        <h3>
                          Будни-<span>{item.weekdays_price}</span> р./сут
                        </h3>
                        <h3>
                          Выходные-<span>{item.weekend_price}</span> р./сут
                        </h3>
                      </div>
                    )
                )}
              </>
            </div>
          </div>
          <div className="content-photo-desc">
            {house.images.map((image) =>
              extensions.includes(image.image.split(".").pop()) ? (
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                  data-id={house.id}
                  alt="room1"
                  key={image.image}
                  onClick={(e) => {
                    openModalHouse(e.target);
                  }}
                />
              ) : (
                <video controls="controls" key={image.image}>
                  <source
                    src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                    type='video/ogg; codecs="theora, vorbis"'
                  />
                  <source
                    src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                    type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                  />
                  <source
                    src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                    type='video/webm; codecs="vp8, vorbis"'
                  />
                </video>
              )
            )}
          </div>
        </div>
      ))}
      <Modal active={modalHouse} setActive={setModalHouse}>
        <div className="modal-house-slider">
          <Swiper
            slidesPerView={1}
            spaceBetween={5}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            {housePhotoList.map(
              (image) =>
                extensions.includes(image.image.split(".").pop()) && (
                  <SwiperSlide key={image}>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                      data-id={image.id}
                      alt="room1"
                      key={image.image}
                      onClick={(e) => {
                        openModalHouse(e.target);
                      }}
                    />
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </div>
      </Modal>
      <div className="card-houses-content-mobile">
        {houseList.map((house) => (
          <div key={house.name} className="content-house-mobile">
            <div className="content-main-mobile">
              <div className="content-photo-main-mobile">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}${house.main_image}`}
                      key={house.id}
                      data-id={house.id}
                      onClick={(e) => {
                        openModalHouse(e.target);
                      }}
                      alt="house1"
                    />
                  </SwiperSlide>
                  {house.images.map((image) =>
                    extensions.includes(image.image.split(".").pop()) ? (
                      <SwiperSlide key={image.image}>
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                          key={house.id}
                          data-id={house.id}
                          onClick={(e) => {
                            openModalHouse(e.target);
                          }}
                          alt="room1"
                        />
                      </SwiperSlide>
                    ) : (
                      <SwiperSlide key={image.image}>
                        <video controls="controls" key={image.image}>
                          <source
                            src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                            type='video/ogg; codecs="theora, vorbis"'
                          />
                          <source
                            src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
                          />
                          <source
                            src={`${process.env.REACT_APP_BACKEND_URL}${image.image}`}
                            type='video/webm; codecs="vp8, vorbis"'
                          />
                        </video>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </div>
              <div className="desc-house-mobile">
                <div className="title-house-mobile">
                  <h3>{house.name}</h3>
                </div>
                <div className="items-house-mobile">
                  {house.services.map((service) => (
                    <div key={service.name} className="item-house-mobile">
                      <h4>{service.name}</h4>
                    </div>
                  ))}
                </div>
                <div className="price-house-mobile">
                  <h2>Прайс</h2>
                  <FormControl
                    sx={{ m: 1, minWidth: 180, maxWidth: 220 }}
                    size="small"
                  >
                    <InputLabel id="demo-select-small-label">
                      Выберите сезон
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={value}
                      label="Выберите сезон"
                      onChange={handleChange}
                    >
                      {house.prices.map((item) => (
                        <MenuItem value={item.type} sx={{ fontSize: "3.5vw" }}>
                          {item.type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {house.prices.map(
                  (item) =>
                    value === item.type && (
                      <div className="price-house">
                        <h3>
                          Будни-<span>{item.weekdays_price}</span> р./сут
                        </h3>
                        <h3>
                          Выходные-<span>{item.weekend_price}</span> р./сут
                        </h3>
                      </div>
                    )
                )}
              </div>
            </div>
            {/* <div className="content-photo-desc-mobile"></div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardsHouse;
