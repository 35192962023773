import React, { useContext } from 'react';
import './style.scss';


const Home = () => {


    return (
        <div className='home' id='home'>
            <div className="content-home ">
                <div className="description ">
                    <div className='logo-home'>
                        <img src="/img/logonew.png" alt="logo" />
                    </div>
                    <h3>база отдыха</h3>
                    <h1>Лесная сказка</h1>
                    <p>Уютная база отдыха в Башкортостане, в которую всегда хочется вернуться</p>
                    <br />
                    <p>Окунувшись в лесную сказку, круглый год Вам гарантирован превосходный отдых с возможностью приобщения к природе</p>
                </div>
                <div className='photo'>
                    <img src="/img/img-home.jpeg" width={704} height={432} alt=""/>
                </div>
            </div>
        </div>
    );
};

export default Home;