import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Link, animateScroll as scroll } from "react-scroll";
import { NavLink, useLocation } from "react-router-dom";
import AppContext from "../../context";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Menu = () => {
  const { pathname } = useLocation();
  const [openBurger, setOpenBurger] = useState(false);
  const { menuList } = useContext(AppContext);

  const openMenu = () => {
    if (window.screen.availWidth <= 480) {
      setOpenBurger((prev) => !prev);
      if (!openBurger) document.querySelector("body").classList.add("active");
      else document.querySelector("body").classList.remove("active");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="menu">
      <div className="navbar-menu">
        <div className="button-back">
          <NavLink to="/">
            <span className="material-symbols-outlined">arrow_circle_left</span>
          </NavLink>
        </div>

        <div className="mobile-btn" onClick={openMenu}>
          {openBurger ? (
            <AiOutlineClose size={35} color="#ffffff" />
          ) : (
            <h1>Меню</h1>
          )}
        </div>
        <div
          className={
            openBurger
              ? "burger-menu-content burger-menu-content active"
              : "burger-menu-content"
          }
          onClick={openMenu}
        >
          <div
            className="navbar-menu-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="nav-menu">
              <ul className="nav-menu-links">
                {menuList.map((obj, index) =>
                  index % 2 === 0 ? (
                    <Link
                      to={`category-${obj.id}`}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={700}
                      onClick={openMenu}
                    >
                      <li className="nav-menu-link">{obj.name}</li>
                    </Link>
                  ) : (
                    <Link
                      to={`category-${obj.id}`}
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={700}
                      onClick={openMenu}
                    >
                      <li className="nav-menu-link">{obj.name}</li>
                    </Link>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="header-menu">
        <h1>Меню</h1>
      </div>
      <div className="fade-top"></div>
      <div className="menu-content">
        <div className="menu-content-left">
          {menuList.map((obj, index) =>
            index % 2 === 0 ? (
              <div
                className="menu-category"
                id={`category-${obj.id}`}
                key={obj.name}
              >
                <h1>{obj.name}</h1>
                {obj.dishes.map((arr) => (
                  <div className="menu-card" key={arr.name}>
                    <div className="menu-card-title">
                      <h2>{arr.name}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: arr.description.replace(
                            new RegExp("\n", "g"),
                            "<br />"
                          ),
                        }}
                      >
                        {}
                      </p>
                    </div>
                    <p>{arr.weight}гр</p>
                    <h3>{arr.price}р.</h3>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )
          )}
        </div>
        <div className="menu-content-right">
          {menuList.map((obj, index) =>
            index % 2 !== 0 ? (
              <div
                className="menu-category"
                id={`category-${obj.id}`}
                key={obj.name}
              >
                <h1>{obj.name}</h1>
                {obj.dishes.map((arr) => (
                  <div className="menu-card" key={arr.name}>
                    <div className="menu-card-title">
                      <h2>{arr.name}</h2>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: arr.description.replace(
                            new RegExp("\n", "g"),
                            "<br />"
                          ),
                        }}
                      >
                        {}
                      </p>
                    </div>
                    <p>{arr.weight}гр</p>
                    <h3>{arr.price}р.</h3>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
