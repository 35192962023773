import React, { useContext } from 'react';
import './style.scss';
import AppContext from '../../context';

const About = () => {

    const { serviceList } = useContext(AppContext);



    return (
        <div className='about' id='about'>
            <div className="content-about">
            <div className="content-about-left ">
                <h1>Здесь живет уют</h1>
                <h2>Подари себе и своим близким незабываемый отдых на природе</h2>
                <div className="cards">
                    {
                        serviceList.map((service) => (
                            <div className="card-info" key={service.name}>
                                <img src={`${process.env.REACT_APP_BACKEND_URL}${service.icon}`} alt="icon" />
                                <p>{service.name}</p>
                                </div>
                        ))
                        
                    }

                </div>
            </div>
            <div className="content-about-right">
                <img className='up-photo' src="/img/photo2.png" alt="" />
                <img className='sup-photo' src="/img/photo3.png" alt="" />
            </div>
            </div>
        </div>
    );
};

export default About;