import React, { useContext } from 'react';
import './style.scss';
import AppContext from '../../context';

const Road = () => {


    const { settingsList } = useContext(AppContext);

    return (
        <div className='road' id='road'>
            <div className="content-road">
                {
                    <div className="content-road-left">
                        <h1>Как до нас добраться</h1>
                        <p>Адрес</p>
                        <p>{settingsList.addres}</p>
                        <br />
                        <br />
                        {/* {
                            console.log(settingsList.phones)
                        } */}
                        <p>Телефон для бронирования</p>
                        <p>+7 (937) 33-66-366</p>
                        <br />
                        <br />
                        <p>Электронная почта</p>
                        <p>lesnaj_skazka@mail.ru</p>
                        <br />
                        <br />
                        <p>Режим работы</p>
                        <p>{settingsList.operating_mode}</p>
                        <div className='btn-excurs'><a href={settingsList.link_to_3d_excursion} target="_blank">3D ЭКСКУРСИЯ</a></div>
                        </div>
                }
                <div className="content-road-right">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54233.26100858545!2d56.41664204466632!3d53.0779708162248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x43d7de96aaaaaaab%3A0xfb2fa7df733385c9!2z0JvQtdGB0L3QsNGPINGB0LrQsNC30LrQsA!5e0!3m2!1sru!2sru!4v1695026367321!5m2!1sru!2sru" 
                    style={{borderRadius: 25}}
                    width="600"
                    height="450" 
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade"
                    className='road-map'>
                </iframe>
                </div>
            </div>

            <div className="footer-contacts">
                <p className='info-left'>ООО "ЛЕСНАЯ СКАЗКА <br />
                "Юридический адрес: <br />
                {settingsList.busines_address}<br />
                Почтовый адрес: <br />
                {settingsList.postal_address}</p>
                <p className='info-right'>
                    ИНН: {settingsList.inn} <br/>
                    КПП: {settingsList.kpp} <br/>
                    <br/>
                    Р/с: {settingsList.rs}<br/>
                    К/с: {settingsList.ks}<br/>
                    Банк: {settingsList.bank}<br/>
                    Отделение в г.Мелеуз<br/>
                    БИК: {settingsList.bik}<br/>
                    ОГРН: {settingsList.ogrn}<br/>
                    ОКАТО: {settingsList.okato}<br/>
                </p>
            </div>
            
        </div>
    );
};

export default Road;