import React from 'react';
import "./style.scss";


const Modal = ({active, setActive, children}) => {

    const openModal = () => {
        setActive(false);
        document.querySelector('body').classList.remove('active');
    }
    
    return (
        <div>
            <div className={active ? "modal active" : "modal"} onClick={openModal}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    {children}
                </div>
                <span className='material-symbols-outlined' onClick={openModal}>close</span>
            </div>
        </div>
    );
};

export default Modal;