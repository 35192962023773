import React, { useContext, useState } from 'react';
import './style.scss';
import Modal from '../../components/Modal/Modal';
import AppContext from '../../context';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination} from 'swiper/modules';


const Gallery = () => {

    const { galleryList } = useContext(AppContext);
    const [photoGallery, setPhotoGallery] = useState([]);
    const [modalActive, setModalActive] = useState(false);


    const setModalInfo = (target) => {
        setModalActive(true);
        document.querySelector('body').classList.add('active');
        const id = target.getAttribute('data-id');
        galleryList.forEach(d => {
            if (d.id == +id) {
                setPhotoGallery(d.images)
            }
        });
    } 
    return (
        <div className='gallery' id='gallery'>
            <div className="content-gallery">
            <div className='fade-top'>
            </div>
            <div className="content-galerry-title">
                <h1>Фотогалерея</h1>
            </div>
                <div className="gallery-photos">
                        
                <div className='gallery-photos-desktop'>
                {
                galleryList.map(obj => (
                    <div className='gallery-photo' key={obj.name} >
                        <h1 className='gallery-title'>
                            {obj.name}
                        </h1>
                        <img src={`${process.env.REACT_APP_BACKEND_URL}${obj.main_image}`} alt="" data-id={obj.id} onClick={(e) => {setModalInfo(e.target)}} />
                    </div>
                ))
                }</div>
                <div className='gallery-photos-mobile'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        >
                {
                galleryList.map(obj => (
                    <SwiperSlide key={obj.name}>
                    <div className='gallery-photo' >
                        <h1 className='gallery-title' onClick={(e) => {setModalInfo(e.target)}} >
                            {obj.name}
                        </h1>
                        <img src={`${process.env.REACT_APP_BACKEND_URL}${obj.main_image}`} data-id={obj.id} alt=""  onClick={(e) => {setModalInfo(e.target)}} />
                    </div>
                    </SwiperSlide>
                ))
                }</Swiper></div>
                </div>
                <Modal active={modalActive} setActive={setModalActive} >
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                        >
                            {
                                photoGallery.map((photo) => (
                                    <SwiperSlide key={photo}><img src={`${process.env.REACT_APP_BACKEND_URL}${photo}`} alt="" /></SwiperSlide>
                                ))
                            }
                    </Swiper>
                </Modal>
            </div>
        </div>
    );
};

export default Gallery;