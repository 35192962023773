import React, { useContext, useState } from 'react';
import './style.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import './swiper.css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { NavLink } from 'react-router-dom';
import AppContext from '../../context';
import Modal from '../../components/Modal/Modal';


const Rest = () => {

    const { restPhotoList } = useContext(AppContext);
    const [madalRest, setModalRest] = useState(false);


    const openModalRest = () => {
        setModalRest(true);
        document.querySelector('body').classList.add('active');
    }

    return (
        <div className='rest' id='rest'>
            <div className="content-rest">
                <div className="column"></div>
                <div className="content-rest-left">
                    <h1>Ресторан</h1>
                    <h3>Изысканная европейская кухня</h3>
                    <h3>Каминный зал</h3>
                    <h3>Бильярдный зал</h3>
                    <h3>Караоке бар</h3>
                    <ul>Праздники  
                        <li>Свадьба </li>
                        <li>Банкеты</li>
                        <li>Корпаративные праздники</li>
                        <li>Романтические свидания</li>
                        <li>Семейные торжества</li>
                    </ul>
                    <h2>Время работы: 10.00-01.00 </h2>
                </div>
                <div className="content-rest-right">
                    <div className="slider">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={0}
                            loop={true}
                            pagination={{
                            clickable: true,
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper">
                                {
                                    restPhotoList.map((image) => (
                                        <SwiperSlide key={image}><img src={`${process.env.REACT_APP_BACKEND_URL}${image}`} alt="" onClick={openModalRest}/></SwiperSlide>
                                    ))
                                }
                        </Swiper>
                        <Modal active={madalRest} setActive={setModalRest} >
                            <div className="slider-photos">
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                loop={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Pagination, Navigation]}
                                className="mySwiper">
                                    {
                                        restPhotoList.map((image) => (
                                            <SwiperSlide key={image}><img src={`${process.env.REACT_APP_BACKEND_URL}${image}`}  alt="" onClick={openModalRest}/></SwiperSlide>
                                        ))
                                    }
                            </Swiper>
                            </div>
                        </Modal>
                    </div>
                </div>
                
            </div>
            <div className="btn-menu">
                <NavLink to='menu'>
                    <button>
                        Меню
                    </button>
                </NavLink>
            </div>
        </div>
    );
};

export default Rest;