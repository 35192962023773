import React, { useContext, useState } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
import './style.scss';
import AppContext from '../../context'
import CardsHouse from '../../components/CardsHouse';


const House = () => {

    const { houseList } = useContext(AppContext);

    const activeMode = "card-house--active";
    const normalMode = "card-house";

    const [clickArrow, setClickArrow] = useState(false);


    const onClickArrow = () => {
        setClickArrow((prev) => !prev);
    }
    
    return (
        <div className='house' id='house'>
            <h1>Наши домики</h1>
            <div className={ clickArrow ? activeMode : normalMode }>
           <CardsHouse />
            </div>
           {
            clickArrow ? 
            <Link
            to='house'
            spy={true}
            smooth={true}
            offset={-70}
            duration={700}>
            <div className="arrow-down" onClick={onClickArrow}>
                <span className="material-symbols-outlined">north</span>
            </div></Link> 
            :
            <div className="arrow-down" onClick={onClickArrow}>
                <span className="material-symbols-outlined">south</span>
            </div>
           }
            
        </div>
    );
};

export default House;